import React from 'react'
import '../styles/Andy.css';
import logo from '../assets/mtg_full_logo.gif';
import crystal from '../assets/andy_bill_fixed.gif';
import frame from '../assets/frame3_fixed.gif';
import DeckView from '../Components/DeckView'
import { Container} from 'react-bootstrap';
import {Navigate, useNavigate} from 'react-router-dom'

export default class Andy extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          value: 'Andy awaits...',
          author: "",
          name: "Deck Name",
          deckObj: null,
          savedDeck: null,
          loading: false,
          loadedRandom : false
        }
    
        this.handleChange = this.handleChange.bind(this);
        this.handleAuthorChange = this.handleAuthorChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaveSubmit = this.handleSaveSubmit.bind(this);
    }
    
    componentDidMount(event){
        if (!this.state.loadedRandom) {
            fetch("https://api.scryfall.com/cards/random").then((res) => res.json())
                .then((data) => this.setState({value: "60 " + data.name, loadedRandom: true}))
        }
    }
      
    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleAuthorChange(event) {
        this.setState({author: event.target.value})
    }

    handleNameChange(event) {
        this.setState({name: event.target.value})
    }
    
    handleSaveSubmit(event) {  
        console.log("save submit", this.state.deckObj)
        this.state.deckObj.name = this.state.name
        fetch( process.env.REACT_APP_ANDY_API_ENDPOINT + "/decks", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({deck: this.state.deckObj, author: this.state.author, name: this.state.name})
        }).then((res) => res.json())
            .then((deckObj) => {
                console.log('I AM DATA', deckObj)
                //alert(`Your Deck ${deckObj.name} was saved`)
                this.setState({savedDeck: deckObj})
              
            }).catch((err) => { 
                console.log("scryFall Error:", err)
                //this.setState({loading: false, deckObj: null})
                alert("Decklist not valid...")
            })
            event.preventDefault()
    }
    
    handleSubmit(event) {
        this.setState({deckObj: null, loading: true})
        let deck = this.state.value
        console.log(deck)
        fetch( process.env.REACT_APP_ANDY_API_ENDPOINT + "/deckcheck", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(deck)
        }).then((res) => res.json())
            .then((deckObj) => {
                //console.log('I AM DATA', deckObj)
                this.setState({deckObj, loading:false, name: deckObj.name})
                this.deckView.scrollIntoView({behavior: "smooth" }) 
            }).catch((err) => { 
                console.log("scryFall Error:", err)
                this.setState({loading: false, deckObj: null})
                alert("Decklist not valid...")
                
            })
        event.preventDefault();
      }
    
      render() {
        // let savedDeck = {savedDeck} = this.state
        // console.log('sd', savedDeck)
        return (
            <div className="Andy">
                {this.state.savedDeck && (
                    <Navigate to={"/deck/" + this.state.savedDeck.id} replace={true} />
                )}
                <header className="Andy-header">
                    <Container style={{paddingRight:0, paddingLeft: 0, marginRight: 0, marginLeft: 0}} className="LogoContainer">
                      {/* <img className="logo" src={logo} alt="logo" /> */}
                    </Container>
                    <p>Paste your deck:</p>
                    <form className="deck-form" onSubmit={this.handleSubmit}>
                        <label>
                        <textarea className="deck-area" value={this.state.value} onChange={this.handleChange} onClick={() => { if (this.state.value === "Andy awaits...") this.setState({value:""})}}/>
                        </label>
                        <input type="submit" value="Is it Andy?" />
                    </form>
                    {/* {( this.state.deckObj?.isLegal && <form className="decksave-form" onSubmit={this.handleSaveSubmit}>
                        <input type="submit" value="Save Deck" />
                        <label>
                        <textarea className="author-area" value={this.state.author} onChange={this.handleAuthorChange}/>
                        </label>
                    </form>)} */}
                    {this.state.loading ? <img src={crystal} width="700" style={{marginTop:10, marginBottom:10}} className="AndyImage"></img> : <img src={frame} width="700" className="AndyImage"></img>}     
                </header>
                <div className="deck-view" ref={(el) => { this.deckView = el; }}> 
                    
                    {(this.state.deckObj && !this.state.loading && (
                    <div>
                        {(this.state.deckObj?.isLegal && 
                            <form className="deck-save-form" onSubmit={this.handleSaveSubmit}>
                                    <textarea className="name-area" value={this.state.name} onChange={this.handleNameChange}/>
                                    <textarea className="author-area" value={this.state.author} placeholder="Wizards Name..." onChange={this.handleAuthorChange}/>
                                <input type="submit" className="save-button" value="Save Deck"/> 
                            </form>)}
                            <DeckView deckObj={this.state.deckObj}/>
                    </div>))}
                
                </div>
            </div>
        );
      }
}
