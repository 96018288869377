import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/DeckCardView.css';
import cardBack from '../assets/Magic_card_back.jpg';

export default function CardView(props) {
    const lands = ["Swamp", "Mountain", "Island", "Forest", "Plains"] 
        const imageUrl = (props.card.scryfall.card_faces) 
            ? 
                (props.card.scryfall.card_faces[0].image_uris) ? props.card.scryfall.card_faces[0].image_uris.border_crop : props.card.scryfall.image_uris.border_crop
            : 
                props.card.scryfall.image_uris.border_crop    
        return (    
            <div>
            {!props.sideboard ?
                <div className = "MainCardView">
                    {props.card && <Container>
                        {console.log("carddd", props.card.scryfall.name, props.card.scryfall)}
                        <Row>
                            <Col>
                                <a href={"/card/" + props.card.scryfall.oracle_id}><img className="DeckCardImage" src = {imageUrl}></img></a>
                            </Col>
                            <Col>
                                <br></br>
                                <i><b className="CardName" style={{fontSize:22}}>{props.card.scryfall.name}</b></i>
                                <p className="CardText"> Copies:  <b>{props.card.copies}</b></p>
                                <p className="CardText">Cost: <b>${(!lands.includes(props.card.name) ? (props.card.totalCost).toFixed(2) : 0)}</b> (${props.card.price})</p>
                                <p className="CardText"><b>{((props.card.totalCost/props.mainValue)*100).toFixed(2)}%</b> of Budget</p>
                            </Col>
                        </Row>
                    </Container>}                
                </div> :
                <div className = "SideCardView">
                {props.card && <Container>
                    <Row>
                        <Col>
                        <a href={"/card/" + props.card.scryfall.oracle_id}><img className="DeckCardImage" src = {imageUrl}></img></a>
                        </Col>
                        <Col>
                            <br></br>
                            <i><b className="CardName">{props.card.scryfall.name}</b></i>
                            <p className="CardText"> Copies:  <b>{props.card.copies}</b></p>
                            <p className="CardText">Cost: <b>${(!lands.includes(props.card.name) ? (props.card.totalCost).toFixed(2) : 0)}</b> (${props.card.price})</p>
                            <p className="CardText"><b>{((props.card.totalCost/props.sideValue)*100).toFixed(2)}%</b> of Budget</p>
                        </Col>
                    
                    </Row>
                </Container>}                
            </div>}
        </div>
        );
    }
