import React from 'react'
import CardView from './DeckCardView'
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/DeckView.css';
import check from '../assets/check.png';
import redx from '../assets/redx.png';
import DeckWarning from './DeckWarning';
import { useLoaderData } from 'react-router-dom';
import { parseISO } from 'date-fns'
import symbol from '../assets/symbols';
import {formatDate} from '../helpers'

export default function DeckView(props) {       
    //console.log("props", props)
    const deck = useLoaderData() || {author: "", createdAt: ""}
    //console.log('deck', deck)
    const deckObj = deck.deckObj || props.deckObj
    //console.log('ola',deckObj)
    //console.log('locader', useLoaderData())
           return (
                <div className = "DeckView">
                    <Container>
                        <br/>
                        <Row>
                            <Col>
                                <row>
                                    <p className="DeckName">{deckObj.name}</p>
                                    {deckObj.colourIdentity.map(colour => (<img src={symbol(colour)} style={{width:30, marginBottom:10}}></img>))}
                                </row>
                                <p className="DeckDate">{deck.createdAt ? parseISO(deck.createdAt).toString() : ""}</p>
                            </Col>
                            <Col>
                                <Row>
                                    <Col><p className="DeckText">Mainboard({deckObj.mainCount}): ${deckObj.mainValue.toFixed(2)}</p></Col>
                                    <Col><p className="DeckSideText">Sideboard({deckObj.sideCount}): ${deckObj.sideValue.toFixed(2)}</p></Col>
                                </Row>
                                <Row>
                                    <Col><b className="DeckText" >Legality:</b></Col>
                                    <Col>{deckObj.isLegal ? <img src={check} width = "40" height = "40" ></img> : <img src={redx} width = "40" height = "40" ></img>}</Col>
                                </Row>
                                <Row>
                                    {/* <Col> */}
                                    <p className="DeckAuthor">{deck.author}</p>
                                
                                    {/* </Col>         */}
                                </Row>
                            </Col>
                        </Row>
                        <br/>
                    </Container>
                    {(deckObj.showWarning && <DeckWarning legality={deckObj.legality}></DeckWarning>)}
                    <Container>
                    <Row>
                        <Col>       
                            <p> MAIN: </p>
                            {deckObj.mainSorted.map(card => {
                                return (<CardView sideboard={false} card={deckObj.cards.main[card]} mainValue={deckObj.mainValue}/>)
                            })}
                        </Col>
                        <Col>
                            <p> SIDE: </p>
                            {deckObj.sideSorted.map(card => {
                                return (<CardView sideboard={true} card={deckObj.cards.side[card]} sideValue={deckObj.sideValue} />)
                            })}
                        </Col>
                    </Row>
                    </Container>
                 </div>
        );
    }

