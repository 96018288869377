import './App.css';
import React from "react";
import Main from "./pages/Main"
import Andy from "./pages/Andy"
import DeckList from "./pages/DeckList"

import {
  createBrowserRouter, RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import CardView from './Components/CardView';
import DeckView from './Components/DeckView';
import DeckTable from './Components/DeckTable';
import NavBar from './Components/NavBar'
import ScrollToTop from "./Components/ScrollToTop";

export default function App() { 
  console.log('ENV', process.env) 
  const router = createBrowserRouter([
    {
      path: "/card/:cardId",
      element: <CardView />,
      loader: ({params}) => {
        //https://api.scryfall.com/cards/search?unique=cards&q=oracle_id=938b4e2c-88d9-4637-bc00-e228920c9a78&order=usd
        return fetch("https://api.scryfall.com/cards/search?unique=cards&q=oracle_id=" + params.cardId +"&order=usd").then((res) => res.json())
      }
    },
    {
      path: "/deck/:deckId",
      element: <DeckView />,
      loader: ({params}) => {
        //https://api.scryfall.com/cards/search?unique=cards&q=oracle_id=938b4e2c-88d9-4637-bc00-e228920c9a78&order=usd
        return fetch( process.env.REACT_APP_ANDY_API_ENDPOINT + "/deck/" + params.deckId).then((res) => res.json())
      }
    },
    {
      path: "/decks",
      element: <DeckTable />,
      loader: ({params}) => { return fetch( process.env.REACT_APP_ANDY_API_ENDPOINT + "/deck").then((res) => res.json())
      }
    },
    {
      path: "/ping",
      element: <DeckTable />,
      loader: ({params}) => { return fetch(process.env.REACT_APP_ANDY_API_ENDPOINT + "/ping").then((res) => res.json())
      }
    },
    {
      path: "/",
      element: <Andy />,
    }
  ])
   
   return (
    <>
    <NavBar/>
    <RouterProvider router ={router}/>
    </>
  );
}