import { useEffect, useState } from "react";
import '../styles/CardDeckTable.css';
import DataTable from 'react-data-table-component';
import { parseISO } from "date-fns";
import symbols from '../assets/symbols'

const columns = [
    {
        name: 'Title',
        selector: row => (<a href = {"/deck/" + row.deckId}><p className="DeckItem">{row.deckName}</p></a>),
        sortable: true
    },
    {
        name: 'Colour',
        selector: row => ( row.colourIdentity.map(colour => <img src={symbols(colour)} style={{width:30, marginLeft:5}}></img>)),
        sortable: true
    },
    {
        name: 'Wizard',
        selector: row => row.author,
        sortable: true
    },
    {
        name: 'Cost Per Spell',
        selector: row => {
            let symbol = ""
            const priceDiff = (row.price - row.priceNow)
            if (priceDiff > 0) symbol= "+"
            return("$" + row.price.toFixed(2) + " (" + symbol + priceDiff.toFixed(2) + ")")
        },
        sortable: true
    },
    {
        name: 'Copies',
        selector: row => row.copies,
        sortable: true
    },
    {
        name: 'Divined Date',
        selector: row => parseISO(row.createdAt).toString(),
        sortable: true
    }

];

export default function CardDeckTable (props) {
    let [userDecks, setUserDecks] = useState([])
    //let userDecks = []
    useEffect(async () => {
        console.log('I AM IN TABLE', props)
        try{
        const response = await fetch(process.env.REACT_APP_ANDY_API_ENDPOINT + "/decksByCardId", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({cardId: props.cardObj.oracle_id})
        })
        const userDecksJson = await response.json()
        console.log('deckos', userDecksJson[0])
        const userDecksWithPrice = userDecksJson.map(deck => { 
            deck.priceNow = parseFloat(props.cardObj.prices.usd) 
            return deck
        })
        console.log(userDecksWithPrice[0])
        setUserDecks(userDecksWithPrice)
        //userDecks = deckos
       } catch(err) {
        console.log('FETCH ERR', err)
       }
    }, [])
    return (
        <div className="DeckTableView">
              {/* <div style={{marginLeft:100, marginRight:100}}> */}
                <DataTable
                    columns={columns}
                    data={userDecks}
                    theme="dark"
                    // striped={true}
                />
            {/* </div> */}
        </div>
    )
}