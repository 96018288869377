import { useEffect, useState } from "react"
import { useLoaderData, useParams } from "react-router-dom"
import {Container, Row, Col} from 'react-bootstrap'
import CardDeckTable from "./CardDeckTable"
import cardBack from '../assets/Magic_card_back.jpg';
import '../styles/CardView.css';

export default function CardView (props) {
    const [card, setCard] = useState(useLoaderData())
    useEffect(() => {
        document.title = `${card.data[0].name}`
    })
    console.log('CARD', card)
    const cardObj = card.data[0]
    
    return (
        <>
        
        <div className = "CardView">
            <Container>
                <Row>
                    <Col>
                        <img className="CardImage" src = {(cardObj.card_faces) ? cardObj.card_faces[0].image_uris.border_crop : cardObj.image_uris.border_crop} alt ={cardObj.name}></img>
                    </Col>
                    <Col>
                        <br></br>
                        <i><b className="CardName" style={{fontSize:22}}>{cardObj.name}</b></i>
                        {/* <p className="CardText"> Copies:  <b>{props.card.copies}</b></p> */}
                        <p className="CardText">Price: (${cardObj.prices.usd})</p>
                        
                        {/* <p className="CardText"><b>{((props.card.totalCost/props.mainValue)*100).toFixed(2)}%</b> of Budget</p> */}
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="DecksTable">
                    <CardDeckTable cardObj={cardObj}></CardDeckTable>
                </div>
            </Container>
        </div>
        
    </>
    )
}