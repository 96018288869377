import React, { useEffect, useState } from 'react'
import '../styles/DeckView.css';
import { RadialChart, Hint } from 'react-vis';
import "react-vis/dist/style.css";
import '../styles/MetaView.css';
import { Container, Col, Row } from 'react-bootstrap';

export default function MetaView(props) {       
    console.log("metaview props", props)
    
    const [metaObj, setMetaObj] = useState({})

    useEffect(async () => {
        console.log('I AM IN META', props)
        try{
        const response = await fetch(process.env.REACT_APP_ANDY_API_ENDPOINT + "/meta")
        const meta = await response.json()
        console.log('meta', meta)
        const colourArray = Object.keys(meta.deckObj.deckColourCount).map(colour => {
           switch (colour) {
            case 'B':
                return {label: "black", color: "black", "angle": meta.deckObj.deckColourCount[colour]}
            case 'U':
                return {label: "blue", color: "blue", "angle":meta.deckObj.deckColourCount[colour]}
            case 'G':
                return {label:"green", color: "green", "angle":meta.deckObj.deckColourCount[colour]}
            case 'R':
                return {label: "red", color: "red", "angle":meta.deckObj.deckColourCount[colour]}
            case 'W':
                return {label: "white", color: "white" ,"angle":meta.deckObj.deckColourCount[colour]}
                                
           }
        })
        meta.colourArray = colourArray
        console.log('array', colourArray)
        setMetaObj(meta)
        //userDecks = deckos
       } catch(err) {
        console.log('FETCH ERR', err)
       }
    }, [])
            return (
                <div className = "MetaView">
                    {(metaObj.deckObj) && ( 
                    <Container>
                        <Row>
                        <Col>
                            <b>Most Expensive Cards:</b><br/><br/>
                            {metaObj.cardObj.mostExpensive.map(card => (
                            <Row>
                                <Col className="ImageCol">
                                    <div className="bg-image hover-zoom">
                                        <a href={"/card/" + card.cardId}><img className="MostExpensiveImage" src={card.imageUrl}></img></a>
                                    </div>
                                </Col>
                                <Col>
                                    <p>{card.cardName} ${card.price}</p>
                                </Col>
                            </Row>
                            ))}                    
                        </Col>
                        <Col>
                        <b>Most Common Mainboard Cards:</b><br/><br/>
                            {metaObj.cardObj.mainFive.map(card => (
                            <Row>
                                <Col className="ImageCol">
                                    <a href={"/card/" + card.cardId}><img className="MostExpensiveImage" src={card.imageUrl}></img></a>
                                </Col>
                                <Col>
                                    <p>{card.cardName}: {card.copies}</p>
                                </Col>
                            </Row>
                            ))}            
                        </Col>
                        <Col>
                        <b>Most Common Sideboard Cards:</b><br/><br/>
                            {metaObj.cardObj.sideFive.map(card => (
                            <Row>
                                <Col className="ImageCol">
                                    <a href={"/card/" + card.cardId}><img className="MostExpensiveImage" src={card.imageUrl}></img></a>
                                </Col>
                                <Col>
                                    <p>{card.cardName}: {card.copies}</p>
                                </Col>
                            </Row>
                            ))}            
                        </Col>
                        <Col>
                            <b> Meta Colour Breakdown</b>
                            <br></br> <br></br>
                            <Row className="PieRow">
                           
                            <RadialChart
                                data={metaObj.colourArray}
                                colorType="literal"
                                radius={140}
                                innerRadius={70}
                                width={300}
                                height={300}
                            />
                            </Row>
                            <h3>Most Prolific Wizard:</h3>
                            <h4>{metaObj.deckObj.mostDecks[0].author + " (" + metaObj.deckObj.mostDecks[0].decks + ")"}</h4>        
                        </Col>
                        </Row>
                    </Container>)} 
                </div>
            
        );
    }

