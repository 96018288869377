import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/DeckWarning.css';
import goblin from '../assets/goblin.gif';


export default class DeckWarning extends React.Component {
    render (){
        return (    
            <div className="Warning">
                <Container>
                    <Row>
                        <Col>
                            <i><b style={{fontSize:24}}>Warning</b></i>
                            <img src ={goblin} height="100"></img>
                            <i><b>Warning</b></i>
                        </Col>
                        {/* <Col><i><b>Warning</b></i><br></br></Col> */}
                        {(this.props.legality.moreThan4.length > 0) && 
                        <Col>
                        <i>Too many copies:</i><br></br>
                            {this.props.legality.moreThan4.map(card => {
                                return (<p className="warningListItem">-{card}</p>)
                            })}
                        </Col>}
                        {(this.props.legality.noScryfallPrice.length > 0) && 
                        <Col>
                        <i>Scyfall did not return a price:</i><br></br>
                            {this.props.legality.noScryfallPrice.map(card => {
                                return (<p className="warningListItem">-{card}</p>)
                            })}
                        </Col>}
                        {(!this.props.legality.mainOnBudget || !this.props.legality.sideOnBudget) && <Col>
                            {(!this.props.legality.mainOnBudget) && <p className="warningListItem">Mainboard is over budget...</p>}
                            {(!this.props.legality.sideOnBudget) && <p className="warningListItem">Sideboard is over budget...</p>}
                            {(!this.props.legality.main60) && <p className="warningListItem">Mainboard has too many cards...</p>}
                            {(!this.props.legality.side15) && <p className="warningListItem">Sideboard has too many cards...</p>}  
                        </Col>}
                    </Row>
                </Container>
            </div>
        );
    }
}
