import black from './symbol_black.png'
import blue from './symbol_blue.png'
import green from './symbol_green.png'
import red from './symbol_red.png'
import white from './symbol_white.png'
import colourless from './symbol_colourless.png'


export default function symbol(colour){
    switch (colour) {
        case 'B':
            return black
        case 'U':
            return blue
        case 'G':
            return green
        case 'R':
            return red
        case 'W':
            return white
        case 'CL':
            return colourless
    }

}