import React from 'react'
import construction from '../assets/construction.gif';
import '../App.css';

export default class Main extends React.Component {
    render (){
        return (
                <div className="App">
                <header className="App-header">
                    <p>
                    my little cyberspaceship
                    </p>
                    <img src={construction} className="App-logo" alt="logo" />
                </header>
                </div>
        );
    }
}
