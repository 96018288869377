import { useLoaderData, Link, useNavigate } from "react-router-dom";
import '../styles/DeckTable.css';
import DataTable from 'react-data-table-component';
import { formatDate } from "../helpers";
import symbols from '../assets/symbols'
import MetaView from "./MetaView";
import { Container } from "react-bootstrap";

const columns = [
    {
        name: 'Title',
        selector: row =>  row.name,//(<Link to= {{ pathname: '/deck/' + `${row.id}` }}><p className="DeckItem">{row.name}</p></Link>),
        sortable: true  
    },
    {
        name: 'Colour',
        selector: row => ( row.deckObj.colourIdentity.map(colour => <img src={symbols(colour)} style={{width:30, marginLeft:5}}></img>)),
        sortable: true
    },
    {
        name: 'Wizard',
        selector: row => row.author,
        sortable: true
    },
    {
        name: 'Main Value',
        selector: row => "$" + row.deckObj.mainValue.toFixed(2),
        sortable: true
    },
    {
        name: 'Side Value',
        selector: row => "$" + row.deckObj.sideValue.toFixed(2),
        sortable: true
    },
    {
        name: 'Divination Date',
        selector: row => formatDate(row.createdAt),
        sortable: true
    }

];

export default function CardDeckTable (props) {
    const decks = useLoaderData()
    console.log("I HAVE UPDATED", decks)
    
    const navigate = useNavigate()
    
    function onRowClick (row,e) {
      console.log('click', row, e)
      
    }


    return (
        <div className="DeckPage">
            <MetaView></MetaView>
            <div className="DeckTableView">
                    <Container>
                        <DataTable
                            columns={columns}
                            data={decks}
                            theme="dark"
                            highlightOnHover= 'true'
                            pointerOnHover='true'
                            defaultSortFieldId={6}
                            defaultSortAsc={false}
                            onRowClicked={(row, e) => navigate(`/deck/${row.id}`)}
                        />
                    </Container>
                </div>
        </div>
    )
}